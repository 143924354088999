import React, { useRef, useEffect, useState, useCallback } from 'react';
import './App.css';
import { collection, addDoc, onSnapshot, query, orderBy, limit } from "firebase/firestore";
import { db } from './firebase';
import { ConnectWallet } from './components/ConnectWallet/ConnectWallet';
import Sidebar from './components/Sidebar';
import './components/Sidebar.css';
import { compressToUTF16, decompressFromUTF16 } from 'lz-string';

const SCREEN_WIDTH = 2560;
const SCREEN_HEIGHT = 1440;
const BOARD_WIDTH = SCREEN_WIDTH * 3;
const BOARD_HEIGHT = SCREEN_HEIGHT * 3;
const MAX_ZOOM = 1;
const MIN_ZOOM = 0.1;
const BORDER_TOP = 330;
const BORDER_BOTTOM = 460;
const BORDER_LEFT_RIGHT = 480;
const LINE_WIDTH = 6;

const CHALK_COLORS = {
  white: 'rgba(255, 255, 255, 0.8)',
  pink: 'rgba(255, 192, 203, 0.8)',
  blue: 'rgba(173, 216, 230, 0.8)',
  red: 'rgba(255, 105, 97, 0.8)',
  green: 'rgba(152, 251, 152, 0.8)',
  yellow: 'rgba(255, 255, 224, 0.8)'
};

function App() {
  const displayCanvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [zoom, setZoom] = useState(1);
  const [tool, setTool] = useState('main');
  const [color, setColor] = useState('white');
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [drawings, setDrawings] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [channel, setChannel] = useState(null);
  const [currentDrawing, setCurrentDrawing] = useState(null);
  const [audio, setAudio] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  useEffect(() => {
    const audioElement = new Audio(`${process.env.PUBLIC_URL}/assets/song/sound.mp3`);
    console.log('Trying to load audio from:', `${process.env.PUBLIC_URL}/assets/song/sound.mp3`);
    audioElement.addEventListener('canplaythrough', () => {
      console.log('Audio loaded successfully');
      setAudio(audioElement);
    });
    audioElement.addEventListener('error', (e) => {
      console.error('Error loading audio:', e);
    });
  }, []);

  useEffect(() => {
    const bc = new BroadcastChannel('drawing_channel');
    setChannel(bc);

    bc.onmessage = (event) => {
      if (event.data.type === 'draw') {
        setDrawings(prevDrawings => [...prevDrawings, event.data.drawing]);
      } else if (event.data.type === 'clear') {
        setDrawings([]);
      }
    };

    return () => bc.close();
  }, []);

  useEffect(() => {
    const q = query(collection(db, "drawings"), orderBy("timestamp", "desc"), limit(100));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      let newDrawings = [];
      snapshot.forEach((doc) => {
        const data = doc.data();
        newDrawings.push({
          ...data,
          points: JSON.parse(decompressFromUTF16(data.compressedPoints))
        });
      });
      setDrawings(newDrawings);
    });

    return () => unsubscribe();
  }, []);

  const getMousePos = useCallback((e) => {
    const rect = displayCanvasRef.current.getBoundingClientRect();
    return {
      x: (e.clientX - rect.left) / zoom + offset.x,
      y: (e.clientY - rect.top) / zoom + offset.y
    };
  }, [zoom, offset]);

  const isInsideBoard = useCallback((x, y) => {
    return x >= BORDER_LEFT_RIGHT && 
           x <= BOARD_WIDTH - BORDER_LEFT_RIGHT &&
           y >= BORDER_TOP && 
           y <= BOARD_HEIGHT - BORDER_BOTTOM;
  }, []);

  const drawLine = useCallback((ctx, points, color) => {
    if (points.length < 2) return;
    
    ctx.strokeStyle = CHALK_COLORS[color];
    ctx.lineWidth = LINE_WIDTH;
    ctx.lineCap = 'round';
    ctx.lineJoin = 'round';
    
    ctx.beginPath();
    ctx.moveTo(points[0].x, points[0].y);
    
    for (let i = 1; i < points.length; i++) {
      const dx = points[i].x - points[i-1].x;
      const dy = points[i].y - points[i-1].y;
      const distance = Math.sqrt(dx * dx + dy * dy);
      
      if (distance < 3) {
        ctx.lineTo(points[i].x, points[i].y);
      } else {
        for (let j = 0; j < distance; j += 3) {
          const x = points[i-1].x + dx * j / distance;
          const y = points[i-1].y + dy * j / distance;
          ctx.lineTo(x + (Math.random() - 0.5) * 2, 
                     y + (Math.random() - 0.5) * 2);
        }
      }
    }
    
    ctx.stroke();
  }, []);

  const drawDisplayCanvas = useCallback(() => {
    if (!backgroundImage) return;

    const displayCanvas = displayCanvasRef.current;
    const ctx = displayCanvas.getContext('2d');
    
    ctx.clearRect(0, 0, displayCanvas.width, displayCanvas.height);
    ctx.save();
    ctx.scale(zoom, zoom);
    ctx.translate(-offset.x, -offset.y);
    
    ctx.drawImage(backgroundImage, 0, 0, BOARD_WIDTH, BOARD_HEIGHT);

    drawings.forEach(drawing => {
      drawLine(ctx, drawing.points, drawing.color);
    });

    if (currentDrawing) {
      drawLine(ctx, currentDrawing.points, currentDrawing.color);
    }

    ctx.strokeStyle = 'rgba(255, 0, 0, 0)';
    ctx.lineWidth = 2 / zoom;
    ctx.strokeRect(BORDER_LEFT_RIGHT, BORDER_TOP, 
                   BOARD_WIDTH - 2 * BORDER_LEFT_RIGHT, 
                   BOARD_HEIGHT - BORDER_TOP - BORDER_BOTTOM);

    ctx.restore();
  }, [backgroundImage, zoom, offset, drawings, drawLine, currentDrawing]);

  const handleResize = useCallback(() => {
    const displayCanvas = displayCanvasRef.current;
    displayCanvas.width = window.innerWidth;
    displayCanvas.height = window.innerHeight;
    drawDisplayCanvas();
  }, [drawDisplayCanvas]);

  const startDrawing = useCallback((e) => {
    const { x, y } = getMousePos(e);
    if (tool !== 'main' && isInsideBoard(x, y)) {
      if (isConnected) {
        console.log("Starting to draw", { tool, isConnected });
        setIsDrawing(true);
        setCurrentDrawing({ points: [{ x, y }], color: color, tool: tool });
      }
    } else if (tool === 'main') {
      setIsDragging(true);
      setDragStart({ x: e.clientX, y: e.clientY });
    }
  }, [tool, getMousePos, isInsideBoard, color, isConnected]);

  const draw = useCallback((e) => {
    if (tool !== 'main' && isDrawing && isConnected) {
      const { x, y } = getMousePos(e);
      if (isInsideBoard(x, y)) {
        setCurrentDrawing(prev => ({
          ...prev,
          points: [...prev.points, { x, y }]
        }));
        
        requestAnimationFrame(drawDisplayCanvas);
        
        if (!isPlaying && audio) {
          audio.play();
          setIsPlaying(true);
        }
      } else {
        endDrawing();
      }
    } else if (tool === 'main' && isDragging) {
      const dx = e.clientX - dragStart.x;
      const dy = e.clientY - dragStart.y;
      
      setOffset(prev => ({
        x: prev.x - dx / zoom,
        y: prev.y - dy / zoom
      }));
      setDragStart({ x: e.clientX, y: e.clientY });
      requestAnimationFrame(drawDisplayCanvas);
    }
  }, [tool, isDrawing, isDragging, zoom, getMousePos, isInsideBoard, drawDisplayCanvas, isPlaying, dragStart, audio, isConnected]);

  const endDrawing = useCallback(() => {
    if (isDrawing && currentDrawing && isConnected) {
      const compressedPoints = compressToUTF16(JSON.stringify(currentDrawing.points));
      
      addDoc(collection(db, "drawings"), {
        color: currentDrawing.color,
        tool: currentDrawing.tool,
        compressedPoints,
        timestamp: new Date().getTime()
      });
      
      setDrawings(prev => [...prev, currentDrawing]);
      setCurrentDrawing(null);
      setIsDrawing(false);
      if (audio) {
        audio.pause();
        audio.currentTime = 0;
      }
      setIsPlaying(false);
    }
    setIsDragging(false);
  }, [isDrawing, currentDrawing, db, audio, isConnected]);

  const handleWheel = useCallback((e) => {
    e.preventDefault();
    const { x: mouseX, y: mouseY } = getMousePos(e);

    const zoomSensitivity = 0.001;
    const newZoom = zoom * (1 - e.deltaY * zoomSensitivity);
    const clampedZoom = Math.max(MIN_ZOOM, Math.min(MAX_ZOOM, newZoom));
    
    const newOffset = {
      x: mouseX - (mouseX - offset.x) * (zoom / clampedZoom),
      y: mouseY - (mouseY - offset.y) * (zoom / clampedZoom)
    };
    
    setZoom(clampedZoom);
    setOffset(newOffset);
    requestAnimationFrame(drawDisplayCanvas);
  }, [zoom, offset, getMousePos, drawDisplayCanvas]);

  const handleToolChange = useCallback((newTool, newColor = color) => {
    console.log("Changing tool", { newTool, newColor, isConnected });  // Pour le débogage
    if (newTool === 'main' || isConnected) {
      setTool(newTool);
      if (newTool === 'craie') {
        setColor(newColor);
      }
    }
  }, [color, isConnected]);

  const handleClear = useCallback(() => {
    if (isConnected) {
      setDrawings([]);
      channel.postMessage({ type: 'clear' });
      drawDisplayCanvas();
    }
  }, [drawDisplayCanvas, channel, isConnected]);

  const handleConnectionChange = useCallback((connected) => {
    console.log("Connection state changed:", connected);  // Pour le débogage
    setIsConnected(connected);
    if (!connected && tool !== 'main') {
      setTool('main');
    }
  }, [tool]);

  const handleSidebarToggle = (isOpen) => {
    setIsSidebarOpen(isOpen);
  };

  useEffect(() => {
    const img = new Image();
    img.src = process.env.PUBLIC_URL + '/assets/img/board2.png';
    img.onload = () => {
      setBackgroundImage(img);
    };
  }, []);

  useEffect(() => {
    const displayCanvas = displayCanvasRef.current;
    
    displayCanvas.width = window.innerWidth;
    displayCanvas.height = window.innerHeight;
    
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    if (backgroundImage) {
      drawDisplayCanvas();
    }
  }, [backgroundImage, drawDisplayCanvas]);

  useEffect(() => {
    if (audio) {
      audio.loop = true;
    }
    
    return () => {
      if (audio) {
        audio.pause();
        audio.currentTime = 0;
      }
    };
  }, [audio]);

  useEffect(() => {
    console.log("isConnected state changed:", isConnected);
  }, [isConnected]);

  return (
    <div className="App" onWheel={handleWheel}>
      <ConnectWallet onConnectionChange={handleConnectionChange} />
      {!isConnected && (
        <div className="connection-message fixed top-0 left-0 w-full bg-black text-white p-2 text-center">
          Vous devez être connecté pour dessiner
        </div>
      )}
      <Sidebar 
        tool={tool}
        color={color}
        isConnected={isConnected}
        handleToolChange={handleToolChange}
        handleClear={handleClear}
        onToggle={handleSidebarToggle}
      />
      <div className="canvas-container" style={{
        position: 'relative',
        width: isSidebarOpen ? '80%' : '100%',
        marginLeft: isSidebarOpen ? '20%' : '0',
        height: '100vh',
        overflow: 'hidden',
        transition: 'width 0.3s, margin-left 0.3s'
      }}>
        <canvas
          ref={displayCanvasRef}
          onMouseDown={startDrawing}
          onMouseMove={draw}
          onMouseUp={endDrawing}
          onMouseOut={endDrawing}
          className="display-canvas"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            cursor: tool !== 'main' ? 'crosshair' : 'move',
          }}
        />
      </div>
      <div className="zoom-scale" style={{ marginLeft: isSidebarOpen ? '20%' : '0' }}>
        {zoom.toFixed(2)}x
      </div>
    </div>
  );
}

export default App;