// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";


const firebaseConfig = {
    apiKey: "AIzaSyBvwYSZ67dFoCieSuYVI_19TMvOw6DNj8w",
    authDomain: "degen-f7aaf.firebaseapp.com",
    projectId: "degen-f7aaf",
    storageBucket: "degen-f7aaf.appspot.com",
    messagingSenderId: "783525413443",
    appId: "1:783525413443:web:ea02dc43344e83e30c4af6",
  };

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
