import { useEffect, useState } from 'react';

export function ConnectWallet({ onConnectionChange }) {
    const [walletAddress, setWalletAddress] = useState(null);
    const [balance, setBalance] = useState(null);
    const [tokens, setTokens] = useState([]);

    const SOLSCAN_API_KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjcmVhdGVkQXQiOjE3MjY0OTIxMzIzMTcsImVtYWlsIjoidm9ka2FraWxsZXVyQGhvdG1haWwuYmUiLCJhY3Rpb24iOiJ0b2tlbi1hcGkiLCJhcGlWZXJzaW9uIjoidjEiLCJpYXQiOjE3MjY0OTIxMzJ9.913IoC3EqldNobhtwLfJHLwzW5yuoo8pcVZfinbGYrc"; // Si nécessaire

    const checkIfWalletIsConnected = async () => {
        try {
            const { solana } = window;
            if (solana && solana.isPhantom) {
                const response = await solana.connect({ onlyIfTrusted: true });
                setWalletAddress(response.publicKey.toString());
                onConnectionChange(true);
                await getBalance(response.publicKey);
                await getTokensFromSolscan(response.publicKey.toString());
            }
        } catch (error) {
            console.error("Error checking if wallet is connected:", error);
        }
    };

    const connectWallet = async () => {
        try {
            const { solana } = window;
            if (solana) {
                const response = await solana.connect();
                setWalletAddress(response.publicKey.toString());
                onConnectionChange(true);
                await getBalance(response.publicKey);
                await getTokensFromSolscan(response.publicKey.toString());
            }
        } catch (error) {
            console.error("Error connecting wallet:", error);
        }
    };

    const disconnectWallet = () => {
        const { solana } = window;
        if (solana && walletAddress) {
            solana.disconnect();
            setWalletAddress(null);
            setBalance(null);
            setTokens([]);
            onConnectionChange(false);
        }
    };

    const getBalance = async (publicKey) => {
        try {
            const connection = new window.solanaWeb3.Connection("https://api.mainnet-beta.solana.com");
            const balance = await connection.getBalance(publicKey);
            setBalance(balance / 1000000000); // Convert lamports to SOL
        } catch (error) {
            console.error("Error fetching balance:", error);
            setBalance(null);
        }
    };

    // Nouvelle fonction pour récupérer les tokens via l'API Solscan
    const getTokensFromSolscan = async (publicKey) => {
        try {
            const response = await fetch(`https://api.solscan.io/account/tokens?address=${publicKey}`, {
                headers: {
                    'Accept': 'application/json',
                    // 'Authorization': `Bearer ${SOLSCAN_API_KEY}` // S'il est nécessaire
                }
            });

            if (!response.ok) {
                throw new Error("Failed to fetch tokens from Solscan");
            }

            const data = await response.json();
            const tokens = data.data.map(token => ({
                mint: token.tokenAddress,
                amount: token.tokenAmount.uiAmount,
                decimals: token.tokenAmount.decimals,
            }));

            setTokens(tokens);
        } catch (error) {
            console.error("Error fetching tokens from Solscan:", error);
            setTokens([]);
        }
    };

    useEffect(() => {
        checkIfWalletIsConnected();
    }, []);

    return (
        <div className="relative z-10">
            {walletAddress ? (
                <div className="fixed top-5 right-5 flex flex-col items-end">
                    <div className="mb-2 flex items-center">
                        <button className="bg-yellow-400 text-black p-2 rounded-md mr-2">
                            <b>{walletAddress.slice(0, 3)}...{walletAddress.slice(-3)}</b>
                        </button>
                        <button 
                            onClick={disconnectWallet}
                            className="bg-red-500 text-white p-2 rounded-md hover:bg-red-600 cursor-pointer"
                        >
                            Disconnect
                        </button>
                    </div>

                    {/* Affiche le solde en SOL */}
                    <div className="bg-gray-800 text-white p-2 rounded-md mb-2">
                        Solana Balance: {balance ? balance.toFixed(2) : 'Loading...'} SOL
                    </div>

                    {/* Affiche la liste des tokens SPL */}
                    <div className="bg-gray-900 text-white p-2 rounded-md">
                        <h3 className="font-bold mb-2">Tokens:</h3>
                        {tokens.length > 0 ? (
                            <ul className="list-disc ml-5">
                                {tokens.map((token, index) => (
                                    <li key={index}>
                                        Mint: {token.mint}, Amount: {token.amount}, Decimals: {token.decimals}
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p>No tokens found</p>
                        )}
                    </div>
                </div>
            ) : (
                <button 
                    onClick={connectWallet} 
                    className="fixed top-5 right-5 bg-violet-500 text-white p-2 rounded-md hover:bg-violet-600 cursor-pointer"
                >
                    Connect Wallet
                </button>
            )}
        </div>
    );
}
