import React, { useState } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'; // Ajout de l'importation des icônes
import DrawingTools from './DrawingTools';

function Sidebar({ tool, color, isConnected, handleToolChange, handleClear, onToggle }) {
  const [isOpen, setIsOpen] = useState(true);

  const toggleSidebar = () => {
    const newIsOpen = !isOpen;
    setIsOpen(newIsOpen);
    onToggle(newIsOpen);
  };

  return (
    <div className={`sidebar ${isOpen ? 'open' : 'closed'}`}>
      <button className="toggle-btn" onClick={toggleSidebar}>
    {isOpen ? <FaArrowLeft style={{ color: 'black' }} /> : <FaArrowRight style={{ color: 'black' }} />}
</button>
      {isOpen && (
        <DrawingTools 
          tool={tool}
          color={color}
          isConnected={isConnected}
          handleToolChange={handleToolChange}
          handleClear={handleClear}
        />
      )}
    </div>
  );
}

export default Sidebar;