import React from 'react';

const CHALK_COLORS = {
  white: 'Blanc',
  pink: 'Rose',
  blue: 'Bleu',
  red: 'Rouge',
  green: 'Vert',
  yellow: 'Jaune'
};

function DrawingTools({ tool, color, isConnected, handleToolChange, handleClear }) {
  return (
    <>
     <div className="tools">
      <button 
        onClick={() => handleToolChange('main')} 
        className={`${tool === 'main' ? 'active' : 'tool-button'} `} // Ajustement de la taille
      >
        Déplacement
      </button>
      
      <select 
        value={color} 
        onChange={(e) => handleToolChange('craie', e.target.value)}
        disabled={!isConnected}
        className={`${!isConnected ? 'disabled' : ''} tool-select`}
      >
        <option value="" disabled>Choisir une couleur</option>
        {Object.entries(CHALK_COLORS).map(([value, label]) => (
          <option key={value} value={value}>{label}</option>
        ))}
      </select>
      <p className='text-white'>YOYOOYOYO</p>
    </div>
    
    </>
  );
}

export default DrawingTools;